import React, { createContext, useEffect, useMemo, useState } from 'react'
import type { Category } from '@centrito/api/models/public'
import { trpc } from '@centrito/app/api/trpc'
import { subhomeScreenContext } from './initialContextState'
import { SubhomeKey, type SubhomeScreenContextType, TabBarOptions } from './types'

export const SubhomeScreenContext = createContext<SubhomeScreenContextType>(subhomeScreenContext)

export interface SubhomeScreenProviderProps {
  children: React.ReactNode
}

export const SubhomeScreenProvider: React.FC<SubhomeScreenProviderProps> = ({ children }) => {
  const [currentScreen, setCurrentScreen] = useState<SubhomeKey>(SubhomeKey.MAIN)
  const [currentTabBarOption, setCurrentTabBarOption] = useState<TabBarOptions>(
    TabBarOptions.EXPLORE,
  )
  const changeScreen = (newScreen: SubhomeKey): void => {
    setCurrentScreen(newScreen)
  }
  const updateTabBarOption = (newTabBarOption: TabBarOptions): void => {
    setCurrentTabBarOption(newTabBarOption)
  }

  const [currentPages, setCurrentPages] = useState<{ [key: string]: number }>({ '/': 0 })
  const updateCurrentPages = (key: string, value: number): void => {
    setCurrentPages((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const [isTabBarSticky, setIsTabBarSticky] = useState<boolean>(false)
  const updateIsTabBarSticky = (isSticky: boolean): void => {
    setIsTabBarSticky(isSticky)
  }

  const [categories, setCategories] = useState<{ [key: string]: Category[] }>({})

  const { data: _categories } = trpc.catalog.category.findMany.useQuery(undefined)

  const homeCategoryNodes: string[] = [
    'MUJER|BODYS',
    'MUJER|CROP_TOPS',
    'COCINA',
    'MASCOTAS|PERROS',
    'MUJER',
    'ACCESORIOS_ELECTRÓNICOS|AUDIO',
    'BELLEZA',
    'BELLEZA|MAQUILLAJE',
    'JOYERÍA_Y_ACCESORIOS|DE_MUJER|JOYERIA',
    'BELLEZA|CUIDADO_CAPILAR',
    'COCINA|ORGANIZADORES',
    'ACCESORIOS_ELECTRÓNICOS',
    'MUJER|BLUSAS',
    'HOGAR',
    'HOMBRE|BUZOS',
    'HOMBRE|ROPA_DEPORTIVA',
    'BELLEZA|CUIDADO_CORPORAL',
    'HOGAR|DECORACIÓN',
    'HOGAR|ROPA_DE_CAMA',
    'MUJER|SACOS',
    'MASCOTAS|GATOS',
    'ACCESORIOS_DE_BELLEZA|CABELLO',
    'ACCESORIOS_DE_BELLEZA|FACIAL',
    'NIÑO|SACOS',
    'NIÑO|CAMISETAS',
    'NIÑA|CONJUNTOS',
    'NIÑA|VESTIDOS',
    'PAPELERÍA|ESCRITURA',
    'ADULTOS',
    'JUGUETES|MUÑECAS',
    'JUGUETES|JUEGOS_DIDÁCTICOS',
    'ACCESORIOS_ELECTRÓNICOS|DISPOSITIVOS_MÓVILES',
    'ACCESORIOS_ELECTRÓNICOS|PARA_COMPUTADOR|TECLADO_Y_MOUSE',
    'JOYERÍA_Y_ACCESORIOS|DE_HOMBRE|BILLETERAS',
  ]

  const oddIndexElements: string[] = homeCategoryNodes.filter((_, index) => (index + 1) % 2 !== 0)

  const evenIndexElements: string[] = homeCategoryNodes.filter((_, index) => (index + 1) % 2 === 0)

  const orderList: string[] = [...oddIndexElements, ...evenIndexElements]

  useEffect(() => {
    if (_categories && Object.keys(categories).length === 0) {
      const newCategoriesObj: { [key: string]: Category[] } = {}
      newCategoriesObj['/'] = []
      _categories.forEach((item) => {
        const key = item.nodes.split('|')[0]
        const rest = item.nodes.split('|').slice(1)
        if (rest.length === 1) {
          if (!newCategoriesObj[key]) {
            newCategoriesObj[key] = []
          }

          newCategoriesObj[key].push(item)
        } else if (rest.length === 0) {
          updateCurrentPages(key, 0)
        }
        if (homeCategoryNodes.includes(item.nodes)) {
          newCategoriesObj['/'].push(item)
        }
      })
      newCategoriesObj['/'].sort((a, b) => orderList.indexOf(a.nodes) - orderList.indexOf(b.nodes))

      setCategories(newCategoriesObj)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_categories])

  const value = useMemo(
    () => ({
      categories,
      currentScreen,
      changeScreen,
      currentTabBarOption,
      updateTabBarOption,
      currentPages,
      updateCurrentPages,
      isTabBarSticky,
      updateIsTabBarSticky,
    }),
    [categories, currentPages, currentScreen, currentTabBarOption, isTabBarSticky],
  )

  return <SubhomeScreenContext.Provider value={value}>{children}</SubhomeScreenContext.Provider>
}
