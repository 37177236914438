import React from 'react'
import PATHS from '@centrito/common/paths'
import type { Category } from '@centrito/api/models/public'
import { DisplayType } from '@centrito/app/features/shopper/home/components/utils/constants'
import { ScrollView, XStack, YStack } from '@centrito/ui/src'
import { LargeSquareFeedButton, SmallSquareFeedButton } from '@centrito/ui/src/components/button'
import { CircleFeedButton } from '@centrito/ui/src/components/button/CircleFeedButton'

interface CategoryScrollViewProps {
  displayType: DisplayType
  bottomElements?: Category[]
  topElements?: Category[]
  categoriesToUse?: Category[]
}

export const CategoryScrollView: React.FC<CategoryScrollViewProps> = ({
  displayType,
  bottomElements,
  topElements,
  categoriesToUse,
}) => {
  if (displayType === DisplayType.CIRCLE && bottomElements && topElements && categoriesToUse)
    return (
      <ScrollView
        key={'key ' + categoriesToUse[0].nodes}
        paddingBottom={20}
        paddingHorizontal={12}
        showsHorizontalScrollIndicator={false}
        horizontal
        alwaysBounceHorizontal={false}
        alwaysBounceVertical={false}
        bounces={false}
        overScrollMode="never"
      >
        <YStack paddingRight={10} gap={'$2'}>
          <XStack alignContent="flex-start" gap={categoriesToUse.length % 2 == 0 ? 0 : 8}>
            {topElements.map((category) => (
              <CircleFeedButton
                key={`category-browser--${category.nodes}`}
                href={{
                  pathname: PATHS.Products.ListByCategoryPrefix,
                  query: {
                    categoryNodesPrefix: category.nodes,
                  },
                }}
                imageSrc={category.assets.tab ?? ''}
                imageAlt={category.nodes}
                label={category.nodesNamed.split('-').pop() ?? ''}
              />
            ))}
          </XStack>
          <XStack>
            {bottomElements.map((category) => (
              <CircleFeedButton
                key={`category-browser--${category.nodes}`}
                href={{
                  pathname: PATHS.Products.ListByCategoryPrefix,
                  query: {
                    categoryNodesPrefix: category.nodes,
                  },
                }}
                imageSrc={category.assets.tab ?? ''}
                imageAlt={category.nodes}
                label={category.nodesNamed.split('-').pop() ?? ''}
              />
            ))}
          </XStack>
        </YStack>
      </ScrollView>
    )
  else if (displayType === DisplayType.SMALL_RECTANGLE && categoriesToUse)
    return (
      <ScrollView
        key={'key ' + categoriesToUse[0].nodes}
        paddingBottom={20}
        paddingHorizontal={12}
        showsHorizontalScrollIndicator={false}
        horizontal
        alwaysBounceHorizontal={false}
        alwaysBounceVertical={false}
        bounces={false}
        overScrollMode="never"
      >
        <XStack paddingRight={10}>
          {categoriesToUse.map((category) => (
            <SmallSquareFeedButton
              key={`category-browser--${category.nodes}`}
              href={{
                pathname: PATHS.Products.ListByCategoryPrefix,
                query: {
                  categoryNodesPrefix: category.nodes,
                },
              }}
              imageSrc={category.assets.tab ?? ''}
              imageAlt={category.nodes}
              label={category.nodesNamed.split('-').pop() ?? ''}
            />
          ))}
        </XStack>
      </ScrollView>
    )
  else if (displayType === DisplayType.LARGE_RECTANGLE && categoriesToUse)
    return (
      <ScrollView
        key={'key ' + categoriesToUse[0].nodes}
        paddingBottom={20}
        paddingHorizontal={12}
        showsHorizontalScrollIndicator={false}
        horizontal
        alwaysBounceHorizontal={false}
        alwaysBounceVertical={false}
        bounces={false}
        overScrollMode="never"
      >
        <XStack paddingRight={10}>
          {categoriesToUse.map((category) => (
            <LargeSquareFeedButton
              key={`category-browser--${category.nodes}`}
              href={{
                pathname: PATHS.Products.ListByCategoryPrefix,
                query: {
                  categoryNodesPrefix: category.nodes,
                },
              }}
              imageSrc={category.assets.tab ?? ''}
              imageAlt={category.nodes}
              label={category.nodesNamed.split('-').pop() ?? ''}
            />
          ))}
        </XStack>
      </ScrollView>
    )
  else return null
}
