import { useMemo } from 'react'
import { useRouter } from 'next/router'
import type { ProductExtendedPublicComposite } from '@centrito/api/nest/catalog/products/domain/composites/product-extended.composite'
import { GetProductsFilterType } from '@centrito/api/shared/enums'
import { trpc } from '@centrito/app/api/trpc'
import { useSelector } from '@centrito/app/store'
import { selectAnonymousUserId, selectUserId } from '@centrito/app/store/selectors'
import posthogClient from '@centrito/app/utils/services/analytics/posthog'
import { CATEGORY_TAB_FILTER, PRODUCT_LIST_TAB_FILTER } from './utils/constants'

const useInfiniteScrollSubhome = (): {
  products: ProductExtendedPublicComposite[] | undefined
  loadMoreProducts: () => void
  productsCount: number | undefined
  isLoading: boolean
  hasNextPage: boolean | undefined
} => {
  const router = useRouter()
  const userId = useSelector(selectUserId)

  const { tab, sub_tab } = router.query
  let filters = {}
  if (tab && typeof tab === 'string') {
    const tabIntValue = parseInt(tab)
    const filterValue = CATEGORY_TAB_FILTER[tabIntValue]
    if (tabIntValue !== 0)
      filters = filterValue === '' ? {} : { [GetProductsFilterType.CATEGORY_PREFIX]: filterValue }
    else if (sub_tab && typeof sub_tab === 'string') {
      const tabIntValue = parseInt(sub_tab)
      const filterValue = PRODUCT_LIST_TAB_FILTER[tabIntValue]
      filters = filterValue === '' ? {} : { [GetProductsFilterType.LIST_ID]: filterValue }
    }
  } else if (sub_tab && typeof sub_tab === 'string') {
    const tabIntValue = parseInt(sub_tab)
    const filterValue = PRODUCT_LIST_TAB_FILTER[tabIntValue]
    filters = filterValue === '' ? {} : { [GetProductsFilterType.LIST_ID]: filterValue }
  }

  const anonymousUserId = useSelector(selectAnonymousUserId)
  const shapedGroup = useMemo(() => posthogClient.getShapedGroup(), [])

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    trpc.catalog.product.findMany.useInfiniteQuery(
      { filters, anonymousUserId, shapedGroup, userId },
      {
        getNextPageParam: (lastPage, allPages) => {
          const loadedCount = allPages.flatMap((page) => page.products).length
          const count = lastPage.count
          return loadedCount <= count ? lastPage.nextCursor : undefined
        },
      },
    )

  const loadMoreProducts = (): void => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }

  const products = data?.pages.flatMap((page) => page.products)
  const productsCount = data?.pages[0].count

  return {
    products,
    loadMoreProducts,
    productsCount,
    isLoading,
    hasNextPage,
  }
}

export default useInfiniteScrollSubhome
