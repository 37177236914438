import React, { useContext, useEffect, useState } from 'react'
import isNil from 'lodash.isnil'
import PATHS from '@centrito/common/paths'
import type { Category } from '@centrito/api/models/public'
import { SubhomeScreenContext } from '@centrito/app/features/shopper/home/context'
import useWindowDimensions from '@centrito/app/utils/hooks/useWindowDimensions'
import { Spinner, Stack, XStack, YStack } from '@centrito/ui/src'
import { CircleFeedButton } from '@centrito/ui/src/components/button/CircleFeedButton'
import { SeeMoreButton } from '@centrito/ui/src/components/button/SeeMoreButton'
import { SubtitleText3 } from '@centrito/ui/src/components/text/new/subtitles/SubtitleText3'
import { CategoryScrollView } from './CategoryScrollView'
import { DisplayType } from './utils/constants'

interface CategoryDisplayProps {
  subhome: string
}

export const CategoryDisplay: React.FC<CategoryDisplayProps> = ({ subhome }) => {
  const [displayType, setDisplayType] = useState<DisplayType>(DisplayType.SMALL_RECTANGLE)
  const [topElements, setTopElements] = useState<Category[]>([])
  const [emptyElements, setEmptyElements] = useState<number>(0)
  const [bottomElements, setBottomElements] = useState<Category[]>([])
  const [isScrollView, setIsScrollView] = useState<boolean>(true)
  const { width: _windowWidth } = useWindowDimensions()
  const displayItems = Math.round(_windowWidth / 85)
  const { categories } = useContext(SubhomeScreenContext)

  const [categoriesToUse, setCategoriesToUse] = useState<Category[]>([])
  useEffect(() => {
    if (!isNil(subhome)) {
      setCategoriesToUse(categories[subhome])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subhome, categories])

  useEffect(() => {
    if (!isNil(categoriesToUse)) {
      if (categoriesToUse.length > 6) {
        setDisplayType(DisplayType.CIRCLE)
        if (categoriesToUse.length >= 10) {
          setIsScrollView(true)
          const middleIndex = Math.floor(categoriesToUse.length / 2)
          if (categoriesToUse.length % 2 === 0) {
            setTopElements(categoriesToUse.slice(0, categoriesToUse.length / 2))
            setBottomElements(categoriesToUse.slice(categoriesToUse.length / 2))
          } else {
            setTopElements(categoriesToUse.slice(0, middleIndex + 1))
            setBottomElements(categoriesToUse.slice(middleIndex + 1))
          }
        } else {
          const totalElements = categoriesToUse.length - displayItems
          if (totalElements > displayItems) {
            setIsScrollView(true)
            setTopElements(categoriesToUse.slice(0, totalElements))
            setBottomElements(categoriesToUse.slice(totalElements))
          } else {
            setIsScrollView(false)
            setTopElements(categoriesToUse.slice(0, displayItems))
            setBottomElements(categoriesToUse.slice(displayItems))
          }
        }
      } else if (categoriesToUse.length >= 4 && categoriesToUse.length <= 6) {
        setIsScrollView(true)
        setDisplayType(DisplayType.SMALL_RECTANGLE)
      } else {
        setIsScrollView(true)
        setDisplayType(DisplayType.LARGE_RECTANGLE)
      }
    }
  }, [categoriesToUse, displayItems])

  useEffect(() => {
    setEmptyElements(topElements.length - bottomElements.length)
  }, [bottomElements, topElements])

  if (isNil(categoriesToUse) || categoriesToUse.length === 0) {
    return (
      <XStack width="100%" height={180} justifyContent="center" alignItems="center">
        <Spinner size="large" color="$samsamBlue" />
      </XStack>
    )
  }

  return (
    <YStack width="100%" marginTop={10}>
      <XStack
        display="flex"
        justifyContent="space-between"
        marginBottom={15}
        paddingHorizontal={15}
      >
        <SubtitleText3 paddingTop={4}>Categorías</SubtitleText3>
        <XStack>
          <SeeMoreButton
            text={'Ver todo'}
            color="$samsamBlue"
            categoryNodesPrefix={subhome === '/' ? undefined : subhome}
          />
        </XStack>
      </XStack>
      {isScrollView && (
        <CategoryScrollView
          displayType={displayType}
          bottomElements={bottomElements}
          topElements={topElements}
          categoriesToUse={categoriesToUse}
        />
      )}
      {displayType === DisplayType.CIRCLE && !isScrollView && (
        <YStack paddingBottom={20} paddingLeft={8} paddingRight={8}>
          <YStack>
            <XStack gap="$3">
              {topElements.map((category) => (
                <CircleFeedButton
                  key={`category-browser--${category.nodes}`}
                  href={{
                    pathname: PATHS.Products.ListByCategoryPrefix,
                    query: {
                      categoryNodesPrefix: category.nodes,
                    },
                  }}
                  imageSrc={category.assets.tab || ''}
                  imageAlt={category.nodes}
                  label={category.nodesNamed.split('-').pop() || ''}
                />
              ))}
            </XStack>
            <XStack gap="$3">
              {bottomElements.map((category) => (
                <CircleFeedButton
                  key={`category-browser--${category.nodes}`}
                  href={{
                    pathname: PATHS.Products.ListByCategoryPrefix,
                    query: {
                      categoryNodesPrefix: category.nodes,
                    },
                  }}
                  imageSrc={category.assets.tab || ''}
                  imageAlt={category.nodes}
                  label={category.nodesNamed.split('-').pop() || ''}
                />
              ))}
              {Array.from({ length: emptyElements }).map((_, index) => (
                <Stack key={`empty-element-${index}`} width={72} />
              ))}
            </XStack>
          </YStack>
        </YStack>
      )}
    </YStack>
  )
}
