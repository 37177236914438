import React from 'react'
import { Link } from 'solito/link'
import PATHS from '@centrito/common/paths'
import { XStack, styled } from '@centrito/ui/src'
import { BodyText3 } from '@centrito/ui/src/components/text/new/body/BodyText3'

export interface TextIconButtonProps {
  categoryNodesPrefix?: string
  text: string
  textColor?: string
  Icon: React.ComponentType
}

const Container = styled(XStack, {
  backgroundColor: '$samsamBlueTranslucent15',
  borderRadius: 8,
  alignItems: 'center',
  gap: 3,
})

export const TextIconButton: React.FC<TextIconButtonProps> = ({
  categoryNodesPrefix,
  text,
  textColor = '$brightRed',
  Icon,
}) => {
  return (
    <Link
      href={
        categoryNodesPrefix !== undefined
          ? categoryNodesPrefix !== ''
            ? {
                pathname: PATHS.Products.ListByCategoryPrefix,
                query: { categoryNodesPrefix },
              }
            : PATHS.Products.ListAll
          : PATHS.Site.Categories
      }
    >
      <Container paddingHorizontal={8} paddingVertical={5}>
        <BodyText3 color={textColor} marginBottom={1} paddingLeft={3}>
          {text}
        </BodyText3>

        <Icon />
      </Container>
    </Link>
  )
}
