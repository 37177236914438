import type { Dispatch, SetStateAction } from 'react'
import React from 'react'
import isNil from 'lodash.isnil'
import { SheetTextButton } from '@centrito/ui/src/components/button/SheetTextButton'
import { TextIconButton } from '@centrito/ui/src/components/button/TextIconButton'
import ArrowRight from '@centrito/ui/src/components/svgs/assets/ArrowRight'

export interface SeeMoreButtonProps {
  isSheetTrigger?: boolean
  categoryNodesPrefix?: string
  setIsOpen?: Dispatch<SetStateAction<boolean>>
  isOpen?: boolean
  text?: string
  color?: string
  children?: React.ReactNode
}
export const SeeMoreButton: React.FC<SeeMoreButtonProps> = ({
  children,
  categoryNodesPrefix,
  isSheetTrigger = false,
  setIsOpen = () => {},
  isOpen = false,
  text = 'Ver todo',
  color = '#FF004F',
}) => {
  if (isSheetTrigger && !isNil(children)) {
    return (
      <SheetTextButton
        text={text}
        textColor={color}
        Icon={ArrowRight}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      >
        {children}
      </SheetTextButton>
    )
  }

  return (
    <TextIconButton
      categoryNodesPrefix={categoryNodesPrefix}
      text={text}
      textColor={color}
      Icon={ArrowRight}
    />
  )
}
