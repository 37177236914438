import React, { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { SubhomeScreenContext } from '@centrito/app/features/shopper/home/context'
import { SubhomeKey } from '@centrito/app/features/shopper/home/context/types'
import { ScrollView, XStack } from '@centrito/ui/src'
import SubHomeSquare from '@centrito/ui/src/components/svgs/assets/SubHomeSquare'
import { DetailText2 } from '@centrito/ui/src/components/text/new/detail/DetailText2'

export interface SubHomeBarProps {}

export const SUBHOME_OPTION_GROUP = [
  { name: 'Todo', href: SubhomeKey.MAIN },
  { name: 'Belleza', href: SubhomeKey.BELLEZA },
  { name: 'Mujer', href: SubhomeKey.MUJER },
  { name: 'Cocina', href: SubhomeKey.COCINA },
  { name: 'Mascotas', href: SubhomeKey.MASCOTAS },
  { name: 'Accesorios de belleza', href: SubhomeKey.ACCESORIOS_DE_BELLEZA },
  { name: 'Niño', href: SubhomeKey.NINO },
  { name: 'Papelería', href: SubhomeKey.PAPELERIA },
  { name: 'Niña', href: SubhomeKey.NINA },
  { name: 'Hombre', href: SubhomeKey.HOMBRE },
  { name: 'Hogar', href: SubhomeKey.HOGAR },
  { name: 'Piñateria', href: SubhomeKey.PIÑATERIA },
  { name: 'Juguetes', href: SubhomeKey.JUGUETES },
  { name: 'Joyería y Accesorios', href: SubhomeKey.JOYERIA_Y_ACCESORIOS },
  { name: 'Calzado', href: SubhomeKey.CALZADO },
  { name: 'Aseo', href: SubhomeKey.ASEO },
  { name: 'Accesorios electrónicos', href: SubhomeKey.ACCESORIOS_ELECTRONICOS },
  { name: 'Suplementos deportivos', href: SubhomeKey.SUPLEMENTOS_DEPORTIVOS },
  { name: 'Maternidad y bebés', href: SubhomeKey.MATERNIDAD },
  { name: 'Accesorios deportivos', href: SubhomeKey.ACCESORIOS_DEPORTIVOS },
  { name: 'Adultos', href: SubhomeKey.ADULTOS },
]

export const SubHomeBar: React.FC<SubHomeBarProps> = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const { changeScreen } = useContext(SubhomeScreenContext)
  const router = useRouter()
  const { tab } = router.query
  const handleOptionPress = (index: number, href: SubhomeKey): void => {
    setActiveIndex(index)
    changeScreen(href)
    router.push({ query: { ...router.query, tab: index, sub_tab: 0 } })
  }

  useEffect(() => {
    if (tab && typeof tab === 'string') setActiveIndex(parseInt(tab))
    else return
  }, [tab])

  return (
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={{
        alignItems: 'center',
      }}
      paddingTop={3}
      backgroundColor={'$pureWhite'}
    >
      {SUBHOME_OPTION_GROUP.map((option, index, array) => (
        <XStack
          key={`${option.name}-subhome-bar`}
          borderColor={activeIndex === index ? '$samsamBlue' : '$mediumGray'}
          borderWidth={1}
          alignItems="center"
          borderRadius={8}
          paddingVertical={5}
          paddingHorizontal={10}
          onPress={(): void => handleOptionPress(index, option.href)}
          marginRight={index < array.length - 1 ? 10 : 0}
          backgroundColor={activeIndex === index ? '$samsamBlue' : undefined}
        >
          <DetailText2
            color={activeIndex === index ? '$almostWhiteGray' : '$mediumGray'}
            marginRight={index === 0 ? 10 : undefined}
            fontSize={11}
          >
            {option.name}
          </DetailText2>
          {index === 0 && <SubHomeSquare stroke={activeIndex === index ? '#FAFAFA' : '#7F7F7F'} />}
        </XStack>
      ))}
    </ScrollView>
  )
}
