import React from 'react'
import isNil from 'lodash.isnil'
import { SolitoImage as Image } from 'solito/image'
import { Link } from 'solito/link'
import PATHS from '@centrito/common/paths'
import { trpc } from '@centrito/app/api/trpc'
import { useSelector } from '@centrito/app/store'
import { selectIsAuthenticated } from '@centrito/app/store/selectors'
import useWindowDimensions from '@centrito/app/utils/hooks/useWindowDimensions'
import { XStack } from '@centrito/ui/src'
import { CouponsBannerSkeleton } from '@centrito/ui/src/components/skeletons/CouponBannerSkeleton'

const PADDING_HORIZONTAL = 15

export const CouponBanner: React.FC = () => {
  const { data, isLoading } = trpc.catalog.homeBanner.findFirst.useQuery({ bannerName: 'Cuponera' })
  const { width } = useWindowDimensions()
  const isAuthenticated = useSelector(selectIsAuthenticated)

  if (isNil(data)) {
    return null
  }

  const { alt, imageUrl } = data

  const adjustedWidth = width - PADDING_HORIZONTAL * 2
  const height = adjustedWidth * 0.12

  if (isLoading) {
    return (
      <XStack flex={1} paddingHorizontal={PADDING_HORIZONTAL} paddingVertical={10}>
        <CouponsBannerSkeleton />
      </XStack>
    )
  }

  return (
    <XStack
      alignItems="center"
      justifyContent="center"
      flex={1}
      width={width}
      paddingHorizontal={PADDING_HORIZONTAL}
      paddingVertical={10}
    >
      <Link href={isAuthenticated ? PATHS.Account.Coupons : PATHS.Auth.Login}>
        <Image
          contentFit="fill"
          style={{ borderRadius: 8 }}
          src={imageUrl}
          alt={alt}
          width={adjustedWidth}
          height={height}
        />
      </Link>
    </XStack>
  )
}
