import React, { type SVGProps } from 'react'

const VerTodoSvg: React.FC = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="29"
      height="16"
      viewBox="0 0 29 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28 0.75H10.6M28 0.75L24.5563 15.25M28 0.75L17.3516 10.7188L10.6 0.75M10.6 0.75L7.15625 15.25M7.15625 15.25H24.5563M7.15625 15.25L15.8563 8.54375M24.5563 15.25L19.3453 8.81562M0.8125 4.375H6.20469M0.8125 8H5.34375M0.8125 11.625H4.4375"
        stroke="#7F7F7F"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default VerTodoSvg
