import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import isNil from 'lodash.isnil'
import { createParam } from 'solito'
import { Link } from 'solito/link'
import PATHS from '@centrito/common/paths'
import { DownloadDialog } from '@centrito/app/components/DownloadDialog'
import { DownloadHeader } from '@centrito/app/components/DownloadHeader'
import { SearchBar } from '@centrito/app/components/SearchBar'
import { SubHomeBar } from '@centrito/app/features/shopper/home/components/SubHomeBar'
import Subhome from '@centrito/app/features/shopper/home/components/Subhome'
import { SubhomeKey } from '@centrito/app/features/shopper/home/context/types'
import NavBarLayoutContent from '@centrito/app/layouts/NavBarLayout/NavBarLayoutContent'
import NavBarLayoutPage from '@centrito/app/layouts/NavBarLayout/NavBarLayoutPage'
import { useDispatch } from '@centrito/app/store'
import { actions } from '@centrito/app/store/slices/userData'
import { Stack, XStack, YStack } from '@centrito/ui/src'
import { BackButtonSmall } from '@centrito/ui/src/components/button'
import SamSamLogo from '@centrito/ui/src/components/svgs/logos/SamSamLogo'

const { useParam } = createParam()

const subhomes = Object.keys(SubhomeKey).map((key, index) => (
  <Subhome subhome={SubhomeKey[key as keyof typeof SubhomeKey]} key={index + '-' + key} />
))

export const MainHome: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const [categoryNodesPrefix] = useParam('categoryNodesPrefix')
  const isShowingBackButtonSmall = !isNil(categoryNodesPrefix)
  const [screenContent, setScreenContent] = useState<React.ReactNode | null>(null)
  const router = useRouter()
  const { tab } = router.query
  const dispatch = useDispatch()

  useEffect(() => {
    if (tab && typeof tab === 'string') setScreenContent(subhomes[parseInt(tab)])
    else if (!tab) setScreenContent(subhomes[0])
    else return
  }, [tab])

  return (
    <React.Fragment>
      <DownloadDialog />
      <NavBarLayoutPage>
        <DownloadHeader />
        <YStack
          width="100%"
          backgroundColor={'#FFFFFF'}
          zIndex={99}
          position="relative"
          paddingHorizontal={12}
          paddingBottom={10}
        >
          <XStack
            backgroundColor={'$pureWhite'}
            alignItems="center"
            height={50}
            width="100%"
            justifyContent="space-between"
          >
            {isShowingBackButtonSmall && <BackButtonSmall color={'$samsamBlue'} />}
            <Stack
              paddingTop={4}
              hoverStyle={{ cursor: 'pointer' }}
              onPress={(): {
                payload: {
                  isScrollingToTop: boolean
                }
                type: 'userData/setScrollingToTop'
              } => dispatch(actions.setScrollingToTop({ isScrollingToTop: true }))}
            >
              <SamSamLogo />
            </Stack>
            <Stack flex={1}>
              <Link href={PATHS.Search.Main}>
                <SearchBar
                  name="main-searchbar"
                  isDisabled={true}
                  placeholder="Buscar"
                  height={30}
                />
              </Link>
            </Stack>
          </XStack>
          <SubHomeBar />
        </YStack>
        <NavBarLayoutContent>{screenContent}</NavBarLayoutContent>
      </NavBarLayoutPage>
    </React.Fragment>
  )
}
