import React from 'react'
import { Stack, XStack } from '@centrito/ui/src'

interface HomeValueProposalCardProps {
  Description: React.ReactNode
  Icon: React.ReactNode
}

export const HomeValueProposalCard: React.FC<HomeValueProposalCardProps> = ({
  Description,
  Icon,
}) => {
  return (
    <XStack
      alignItems="center"
      justifyContent="space-between"
      backgroundColor="#FAFAFA"
      paddingVertical={7}
      paddingHorizontal={15}
      borderWidth={1}
      borderColor="#EBF0F1"
      borderRadius={8}
      width="50%"
    >
      <Stack width="80%">{Description}</Stack>
      <Stack width="20%">{Icon}</Stack>
    </XStack>
  )
}
