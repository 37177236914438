import React from 'react'
import { XStack, YStack } from '@centrito/ui/src'
import CoinsMoney from '@centrito/ui/src/components/svgs/assets/CoinsMoney'
import SendFast from '@centrito/ui/src/components/svgs/assets/SendFast'
import { DetailText1 } from '@centrito/ui/src/components/text/new/detail/DetailText1'
import { DetailText2 } from '@centrito/ui/src/components/text/new/detail/DetailText2'
import { HomeValueProposalCard } from './Card'

export const HomeValueProposalCards: React.FC = () => {
  return (
    <XStack width="100%" gap={2} justifyContent="space-between" paddingHorizontal={15}>
      <HomeValueProposalCard
        Description={
          <YStack>
            <DetailText1 color="#7F7F7F">Envíos gratis</DetailText1>
            <DetailText2 color="#7F7F7F" numberOfLines={2}>
              A puntos de recolección
            </DetailText2>
          </YStack>
        }
        Icon={<SendFast />}
      />
      <HomeValueProposalCard
        Description={
          <YStack>
            <DetailText2 color="#7F7F7F">Tenemos pagos</DetailText2>
            <DetailText1 color="#7F7F7F">Contraentrega</DetailText1>
          </YStack>
        }
        Icon={<CoinsMoney />}
      />
    </XStack>
  )
}
