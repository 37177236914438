import React from 'react'
import { SolitoImage as Image } from 'solito/image'
import { Link } from 'solito/link'
import { Stack, YStack } from '@centrito/ui/src'
import { DetailText3 } from '@centrito/ui/src/components/text/new/detail/DetailText3'

export interface SmallSquareFeedButtonProps {
  href: { pathname?: string | null; query?: { categoryNodesPrefix: string } }
  imageSrc: string
  imageAlt: string
  label: string
}

export const SmallSquareFeedButton: React.FC<SmallSquareFeedButtonProps> = ({
  href,
  imageSrc,
  imageAlt,
  label,
}) => {
  return (
    <Link href={href}>
      <YStack
        margin={8}
        width={90}
        height={107}
        overflow="hidden"
        borderRadius={8}
        backgroundColor="$pureWhite"
        shadowColor="$translucentBlack25"
        shadowOffset={{ width: 0.5, height: 2 }}
        shadowRadius={5}
      >
        <Stack height={69} overflow="hidden" borderRadius={3}>
          <Image src={imageSrc} alt={imageAlt} contentFit="cover" fill />
        </Stack>
        <Stack justifyContent="center" alignItems="center" paddingTop={5} paddingHorizontal={1}>
          <DetailText3
            textAlign="center"
            justifyContent="center"
            color="$Gray2"
            paddingHorizontal={1}
          >
            {label}
          </DetailText3>
        </Stack>
      </YStack>
    </Link>
  )
}
