import React, { type SVGProps } from 'react'

const CoinsMoneySVG: React.FC = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28 3.31094C28 4.58723 24.3483 5.62187 19.8438 5.62187C15.3392 5.62187 11.6875 4.58723 11.6875 3.31094C11.6875 2.03464 15.3392 1 19.8438 1C24.3483 1 28 2.03464 28 3.31094ZM28 3.31094V21.3453C28 22.6141 24.3297 23.6563 19.8438 23.6563C18.8922 23.6563 17.9859 23.6109 17.125 23.5203M11.6875 6.84531C11.6875 8.11406 15.3578 9.15625 19.8438 9.15625C24.3297 9.15625 28 8.11406 28 6.84531M11.6875 10.4703C11.6875 11.7391 15.3578 12.7812 19.8438 12.7812C24.3297 12.7812 28 11.7391 28 10.4703M11.6875 14.0953C11.6875 15.3641 15.3578 16.4062 19.8438 16.4062C24.3297 16.4062 28 15.3641 28 14.0953M17.125 19.8953C17.9859 19.9859 18.8922 20.0312 19.8438 20.0312C24.3297 20.0312 28 18.9891 28 17.7203M11.6875 16.4516V3.49219M17.125 18.6266C17.125 19.9029 13.4733 20.9375 8.96875 20.9375C4.46418 20.9375 0.8125 19.9029 0.8125 18.6266C0.8125 17.3503 4.46418 16.3156 8.96875 16.3156C13.4733 16.3156 17.125 17.3503 17.125 18.6266ZM17.125 18.6266V25.8766C17.125 27.1453 13.4547 28.1875 8.96875 28.1875C4.48281 28.1875 0.8125 27.1453 0.8125 25.8766V18.8078M0.8125 22.2516C0.8125 23.5203 4.4375 24.5625 8.96875 24.5625C13.5 24.5625 17.125 23.5203 17.125 22.2516"
      stroke="#7F7F7F"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default CoinsMoneySVG
