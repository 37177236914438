import * as React from 'react'

const SvgComponent = ({ fill = '#444', stroke = '#444', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill={fill}
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m17.5 6.666-7.5-5-7.5 5 7.5 5 7.5-5Z"
    />
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17.5 10 10 15l-7.5-5"
    />
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m17.5 13.334-7.5 5-7.5-5"
    />
  </svg>
)

export default SvgComponent
